import type { VoucherHook } from "@/types/centra";

import Input from "../Input";
import styles from "./vouchers.module.css";

const UI = (props: { voucher: VoucherHook }) => {
  const { voucher } = props;
  const {
    code,
    add,
    remove,
    activateInput,
    deactivateInput,
    showInput,
    showRemove,
    error,
    updateCode,
    isValid
  } = voucher;
  if (showInput)
    return (
      <form className={styles.form} onSubmit={add}>
        <Input
          placeholder="Voucher code"
          value={code}
          onChange={updateCode}
          className={error && styles.inputError}
          isValid={isValid}
        />
        <span className={styles.buttons}>
          {error && <p className={styles.error}>{error}</p>}
          <button onClick={deactivateInput} className={styles.close}>
            Close
          </button>
          <button>Apply</button>
        </span>
      </form>
    );
  if (showRemove)
    return (
      <div className={styles.container}>
        {/* <span className={styles.priceContainer}>
          <p>Discount:</p> <p>{amount}</p>
        </span> */}
        <div className={styles.activeVoucher}>
          <span className={styles.subContainer}>
            <p>Voucher </p>
            <p>
              <strong>{code}</strong>
            </p>
          </span>
          <span className={styles.subContainer}>
            <span></span>
            <button onClick={remove} className={styles.button}>
              Remove
            </button>
          </span>
        </div>
      </div>
    );
  return (
    <button className={styles["add-voucher"]} onClick={activateInput}>
      <span>Add voucher</span>
    </button>
  );
};

export default UI;
