"use client";
import "./cart.css";

import * as Dialog from "@radix-ui/react-dialog";
import * as Tabs from "@radix-ui/react-tabs";
import clsx from "clsx";
import { useEffect, useState } from "react";

import CartIcon from "@/components/icons/CartIcon";
import useLocation from "@/lib/hooks/useLocation";
import { useCart } from "@/lib/hooks/useSelection";
import {
  convertCartToGTagItem,
  eventViewCart
} from "@/lib/utils/Analytics/Functions";

import { useIsMutating } from "@tanstack/react-query";
import CartContent from "./CartContent";
import CartHeader from "./CartHeader";
import Wishlist from "./Wishlist";
import styles from "./cart.module.css";

const CartTrigger = ({ quantity }: { quantity?: number }) => {
  return (
    <Dialog.Trigger asChild>
      <button style={{ position: "relative" }}>
        <p className={clsx(styles.cart__text__desktop, "underline-hover")}>
          Cart <span>({quantity || 0})</span>
        </p>

        {quantity !== undefined && quantity > 0 && (
          <div className={styles.mobile__cart__indicator}>
            <span>{quantity}</span>
          </div>
        )}

        <p className={styles.cart__text__mobile}>
          <CartIcon />
          <span className="sr-only">Cart</span>
        </p>
      </button>
    </Dialog.Trigger>
  );
};

const Cart = () => {
  const [selectedTab, setSelectedTab] = useState<"cart" | "wishlist">("cart");
  const { items, modal, cart } = useCart();
  const { currency } = useLocation();
  const isMutatingPosts = useIsMutating({ mutationKey: ["selection"] });

  useEffect(() => {
    if (modal.isOpen) {
      setSelectedTab("cart");
    }
  }, [modal.isOpen]);

  const toggleModal = () => {
    if (modal.isOpen) {
      eventViewCart({
        currency: currency || "",
        items: items.map((item) => convertCartToGTagItem(item)),
        value: cart.summary?.prices.totalAsNumber
      });
    }
    modal.toggleModal();
  };

  return (
    <Dialog.Root open={modal.isOpen} onOpenChange={toggleModal}>
      <CartTrigger quantity={cart.summary?.totalQuantity} />
      <Dialog.Portal>
        <Dialog.Overlay
          className={clsx(styles["dialog-overlay"], "dialog-overlay")}
        />
        <Dialog.Content
          className={clsx(styles["cart-dialog"], "dialog-content")}
        >
          <Tabs.Root
            className={styles["tabs-root"]}
            defaultValue="cart"
            value={selectedTab}
            onValueChange={(value: "cart" | "wishlist") =>
              setSelectedTab(value)
            }
          >
            <CartHeader />

            <CartContent setTab={setSelectedTab} />
            <Wishlist setTab={setSelectedTab} />
          </Tabs.Root>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default Cart;
