"use client";
import "../Cart/cart.css";

import * as Dialog from "@radix-ui/react-dialog";
import * as Tabs from "@radix-ui/react-tabs";
import clsx from "clsx";
import { useEffect, useState } from "react";

import useLocation from "@/lib/hooks/useLocation";
import {
  convertCartToGTagItem,
  eventViewCart
} from "@/lib/utils/Analytics/Functions";

import Heart from "@/components/icons/Heart";
import useWishlistModal from "@/lib/hooks/useSelection/cart/useWishlist";
import { useIsMutating } from "@tanstack/react-query";
import WishlistTab from "../Cart/Wishlist";
import cartStyles from "../Cart/cart.module.css";
import CartContent from "./CartContent";
import WishlistHeader from "./WishlistHeader";
import styles from "./wishlist.module.css";

const WishlistTrigger = () => {
  return (
    <Dialog.Trigger asChild>
      <button className={styles.wishlistButton}>
        <span className={clsx("underline-hover", styles.animation)}>
          <Heart />
        </span>
      </button>
    </Dialog.Trigger>
  );
};

// Dupilcated code from Cart since Radix was making problems.
const Wishlist = () => {
  const [selectedTab, setSelectedTab] = useState<"cart" | "wishlist">(
    "wishlist"
  );
  const { items, modal, cart } = useWishlistModal();
  const { currency } = useLocation();
  const isMutatingPosts = useIsMutating({ mutationKey: ["selection"] });

  useEffect(() => {
    if (modal.isOpen) {
      setSelectedTab("wishlist");
    }
  }, [modal.isOpen]);

  const toggleModal = () => {
    if (modal.isOpen) {
      eventViewCart({
        currency: currency || "",
        items: items.map((item) => convertCartToGTagItem(item)),
        value: cart.summary?.prices.totalAsNumber
      });
    }
    modal.toggleModal();
  };

  return (
    <Dialog.Root open={modal.isOpen} onOpenChange={toggleModal}>
      <WishlistTrigger />

      <Dialog.Portal key={"dialog-wishlist"}>
        <Dialog.Overlay
          className={clsx(cartStyles["dialog-overlay"], "dialog-overlay")}
        />
        <Dialog.Content
          className={clsx(cartStyles["cart-dialog"], "dialog-content")}
        >
          <Tabs.Root
            className={cartStyles["tabs-root"]}
            defaultValue={"wishlist"}
            value={selectedTab}
            onValueChange={(value: "cart" | "wishlist") =>
              setSelectedTab(value)
            }
          >
            <WishlistHeader />
            <CartContent setTab={setSelectedTab} />
            <WishlistTab setTab={setSelectedTab} />
          </Tabs.Root>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default Wishlist;
