import type { InputProps } from "./Input";
import styles from "./input.module.css";

interface UIProps extends InputProps {
  conditionalStyle?: string;
}

const UI = (props: UIProps) => {
  const {
    type,
    placeholder,
    value,
    onChange,
    className,
    disabled = false,
    conditionalStyle = "",
    message = "",
    required = false,
    name,
    autocomplete,
  } = props;
  return (
    <>
      <label
        className={`${className}  ${styles.label} ${conditionalStyle}`}
        htmlFor={name}
      >
        <p className={`${conditionalStyle} ${styles.message}`}>{message} </p>
        <input
          type={type}
          name={name}
          autoComplete={autocomplete}
          value={value}
          onChange={onChange}
          className={`${styles.input} ${conditionalStyle}`}
          disabled={disabled}
        />
        <p className={`${styles.placeholder} ${conditionalStyle}  `}>
          {placeholder}
          {required && <span className={styles.required}>*</span>}
        </p>
        {props?.children}
      </label>
    </>
  );
};

export default UI;
