"use client";
import { Button } from "@/components/atoms";
import useLocation from "@/lib/hooks/useLocation";
import { useSelection } from "@/lib/hooks/useSelection";
import styles from "./index.module.css";

import usePersistedStore from "@/lib/stateManagement/persistedState/persistedStore";
import { NewsletterSignupStoryblok } from "@/types/storyblok-blok-types";
import * as Dialog from "@radix-ui/react-dialog";
import clsx from "clsx";
import isEqual from "lodash.isequal";
import { FormEvent, useEffect, useState } from "react";

type Subscribed = {
  hasSubscribed: boolean;
  closedAt?: string;
  closedCount: number;
};

const defaultSubscribed: Subscribed = {
  hasSubscribed: false,
  closedCount: 0,
  closedAt: ""
};

const setLocalSubscribedState = (subscribed: Subscribed) => {
  localStorage.setItem("newsletterSubscribed", JSON.stringify(subscribed));
};

const getLocalSubscribeState = () => {
  try {
    const newsletterToken = localStorage.getItem("newsletterSubscribed");
    return JSON.parse(newsletterToken) || defaultSubscribed;
  } catch (e) {
    return defaultSubscribed;
  }
};

const isOverTwoWeeksAgo = (modalLastClosedDate: Date) => {
  const TODAYS_DATE = new Date();
  const TWO_WEEKS = new Date(TODAYS_DATE.setDate(TODAYS_DATE.getDate() - 14));
  const isOlderThanTwoWeeks =
    new Date(modalLastClosedDate).getTime() < TWO_WEEKS.getTime();

  return isOlderThanTwoWeeks;
};

export const NewsLetterPopup = ({
  blok
}: {
  blok: NewsletterSignupStoryblok;
}) => {
  const token = usePersistedStore((store) => store.token);
  const location = useLocation();
  const { loggedIn } = useSelection();
  const [email, setEmail] = useState("");
  const [firstName, setFirstname] = useState("");
  const [subscribedState, setSubscribedState] = useState<Subscribed>(null);
  const [modalOpen, setModalOpen] = useState(blok?.showNewsletterPopup);
  const [result, setResult] = useState(false);
  const [country, setCountry] = useState(location.country);
  const [locationState, setLocationState] = useState(location.location);
  const [oneMinutePassed, setOneMinutePassed] = useState(false);
  const [subscribedErrorState, setSubscribedErrorState] = useState(false);

  useEffect(() => {
    const timoutID = setTimeout(() => {
      setOneMinutePassed(true);
    }, 2_000);
    return () => {
      clearTimeout(timoutID);
    };
  }, []);

  useEffect(() => {
    setSubscribedState(getLocalSubscribeState());
  }, []);

  useEffect(() => {
    if (isEqual(location?.location, country)) {
      return;
    }
    setCountry(location?.location);
    setLocationState(location?.state);
  }, [location, country]);

  const subscribeToNewsletter = async (e: FormEvent) => {
    e.preventDefault();
    const apiUrl = process.env.NEXT_PUBLIC_CENTRA_CHECKOUT_API;
    const res = await fetch(`${apiUrl}/newsletter-subscription`, {
      method: "POST",
      headers: {
        "API-Token": token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email: email,
        country: country
      })
    });
    if (!res.ok) {
      setSubscribedErrorState(true);
      return new Error(res.statusText.toString());
    }
    const json = await res.json();
    setResult(json.subscribed);
    setSubscribedErrorState(false);
    setLocalSubscribedState({ ...subscribedState, hasSubscribed: true });
  };

  const hasClosedAndClosedOverTwoWeeksAgo =
    subscribedState?.closedAt && subscribedState?.closedCount >= 3
      ? isOverTwoWeeksAgo(new Date(subscribedState?.closedAt))
      : true;

  const isNotLoggedInOrSubscribed =
    !loggedIn || (!!loggedIn && !loggedIn?.newsletter);

  const closedLessThanThreeTimes: boolean = subscribedState?.closedCount < 3;

  const isOpen =
    modalOpen &&
    isNotLoggedInOrSubscribed &&
    !!subscribedState &&
    hasClosedAndClosedOverTwoWeeksAgo &&
    closedLessThanThreeTimes &&
    !subscribedState.hasSubscribed &&
    oneMinutePassed;

  const handleOpenChange = (open: boolean) => {
    setModalOpen(open);
    if (!!open) return;
    const newSubscribeState: Subscribed = {
      ...getLocalSubscribeState(),
      closedAt: new Date().toISOString(),
      closedCount: isOverTwoWeeksAgo(new Date(subscribedState?.closedAt))
        ? 0
        : subscribedState.closedCount + 1
    };

    setSubscribedState(newSubscribeState);
    setLocalSubscribedState(newSubscribeState);
  };

  return (
    <Dialog.Root
      open={!!isOpen}
      onOpenChange={(e) => {
        handleOpenChange(e);
      }}
    >
      <Dialog.Overlay className="dialog-overlay" />
      <Dialog.Content className={clsx("dialog-content-fade-in", styles.modal)}>
        <Dialog.Close className={styles.close}>X</Dialog.Close>

        {!result ? (
          <>
            {subscribedErrorState ? (
              <>
                {blok?.errorTitle && (
                  <h1 className={clsx("preamble", styles["text-center"])}>
                    {blok?.errorTitle}
                  </h1>
                )}
                {blok?.errorMessage && (
                  <p
                    className={clsx(
                      "uppercase h4",
                      styles["text-center"],
                      styles.ingress,
                      styles.errorMessage
                    )}
                  >
                    {blok?.errorMessage}
                  </p>
                )}
              </>
            ) : (
              <>
                {blok?.title && (
                  <h1 className={clsx("preamble", styles["text-center"])}>
                    {blok.title}
                  </h1>
                )}
                {blok?.preamble && (
                  <p
                    className={clsx(
                      "uppercase h4",
                      styles["text-center"],
                      styles.ingress
                    )}
                  >
                    {blok.preamble}
                  </p>
                )}
              </>
            )}

            <form onSubmit={subscribeToNewsletter} className={styles.formgroup}>
              <div className={styles.inputgroup}>
                <input type="hidden" name="_country" value={country || ""} />
                <input
                  type="hidden"
                  name="_state"
                  value={locationState || ""}
                />
                <input
                  className={styles.input}
                  type="text"
                  placeholder="First Name"
                  id="firstname"
                  name="firstname"
                  value={firstName || ""}
                  onChange={(e) => setFirstname(e.target.value)}
                />
                <input
                  className={styles.input}
                  type="email"
                  placeholder="Email"
                  id="email"
                  name="email"
                  required
                  value={email || ""}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className={styles["modal-button-container"]}>
                <Button
                  color="black"
                  textColor="white"
                  grow
                  type="submit"
                  className="uppercase"
                >
                  SUBSCRIBE
                </Button>
              </div>
            </form>
            {blok?.description && blok?.description.length > 0 && (
              <p className={styles.policyText}>
                {blok.description[0].body.content?.[0].content?.[0].text}
              </p>
            )}
          </>
        ) : (
          <>
            {blok?.sucessTitle && (
              <h1 className={clsx("preamble", styles["text-center"])}>
                {blok?.sucessTitle}
              </h1>
            )}
            {blok?.successMessage && (
              <p
                className={clsx(
                  "uppercase h4",
                  styles["text-center"],
                  styles.success
                )}
              >
                {blok?.successMessage}
              </p>
            )}
          </>
        )}
      </Dialog.Content>
    </Dialog.Root>
  );
};
