/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
"use client";
import { usePathname } from "next/navigation";
import { useEffect } from "react";

import { pageview } from "./Functions";

const GoogleAnalytics = () => {
  const pathname = usePathname();
  useEffect(() => {
    pageview(pathname);
  }, [pathname]);
  return <></>;
};

const Analytics = () => {
  return <GoogleAnalytics />;
};

export default Analytics;
