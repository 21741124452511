const CartIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14}
      height="15.578"
      viewBox="0 0 14 15.578"
    >
      <g
        id="Icon_feather-shopping-bag"
        data-name="Icon feather-shopping-bag"
        transform="translate(-4.5 0.5)"
      >
        <g
          id="Path_32844"
          data-name="Path 32844"
          transform="translate(0 -1.308)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
            d="M4.5,6.308v8.819a1.431,1.431,0,0,0,1.556,1.26H16.944a1.431,1.431,0,0,0,1.556-1.26V6.308Z"
            stroke="none"
          />
          <path
            d="M 5.500001907348633 7.307510375976562 L 5.500001907348633 15.12620067596436 C 5.500001907348633 15.19087028503418 5.697031021118164 15.38601112365723 6.055551528930664 15.38601112365723 L 16.9444408416748 15.38601112365723 C 17.30297088623047 15.38601112365723 17.5 15.19087028503418 17.5 15.12620067596436 L 17.5 7.307510375976562 L 5.500001907348633 7.307510375976562 M 4.500001907348633 6.307510375976562 L 18.5 6.307510375976562 L 18.5 15.12620067596436 C 18.5 15.82197093963623 17.80355072021484 16.38601112365723 16.9444408416748 16.38601112365723 L 6.055551528930664 16.38601112365723 C 5.196441650390625 16.38601112365723 4.500001907348633 15.82197093963623 4.500001907348633 15.12620067596436 L 4.500001907348633 6.307510375976562 Z"
            stroke="none"
            fill="#000"
          />
        </g>
        <path
          id="Path_32846"
          data-name="Path 32846"
          d="M5.7,0A2.849,2.849,0,0,1,2.849,2.849,2.849,2.849,0,0,1,0,0"
          transform="translate(14.349 2.849) rotate(180)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1}
        />
      </g>
    </svg>
  );
};

export default CartIcon;
