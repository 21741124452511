import { useMutation, useQueryClient } from "@tanstack/react-query";

import formatSelection from "@/lib/centra/formatters/formatSelection";
import usePersistedStore from "@/lib/stateManagement/persistedState/persistedStore";

const setPromo = async (
  variables: { code: string; remove: boolean },
  customerToken: string
) => {
  const api = process.env.NEXT_PUBLIC_CENTRA_CHECKOUT_API;
  const res = fetch(`${api}/vouchers`, {
    method: !variables.remove ? "POST" : "DELETE",
    headers: { "API-token": customerToken || "" },
    body: JSON.stringify({
      voucher: variables.code
    })
  })
    .then((res) => {
      if (!res.ok) throw Error("Invalid voucher code");
      return res.json();
    })
    .then((data) => {
      if (data === null || Object.prototype.hasOwnProperty.call(data, "errors"))
        throw Error("Invalid voucher code");
      return data;
    })
    .catch((err) => {
      throw err;
    });

  return res;
};

export const usePromoMutation = () => {
  const queryClient = useQueryClient();
  const customerToken = usePersistedStore((state) => state.token);
  const promo = useMutation(
    (variables: { code: string; remove: boolean }) =>
      setPromo(variables, customerToken),
    {
      onError: async (error: Error) => {
        return error;
      },
      onSuccess: async (data) => {
        queryClient.setQueryData(["selection"], formatSelection(data));
      }
    }
  );

  return { promo };
};
