"use client";
import { Close, Title } from "@radix-ui/react-dialog";
import * as Tabs from "@radix-ui/react-tabs";

import useCart from "@/lib/hooks/useSelection/cart";
import { useWishlistItems } from "@/lib/hooks/useWishlist";

import styles from "./cart.module.css";
const CartHeader = () => {
  const { cart } = useCart();
  const data = useWishlistItems();
  return (
    <div className={styles["cart-header"]}>
      <Tabs.List aria-label="Manage your account">
        <Tabs.Trigger value="cart" className={styles.title}>
          <Title className={styles.title}>
            SHOPPING CART (
            {cart.summary?.totalQuantity && `${cart.summary?.totalQuantity}`})
          </Title>
        </Tabs.Trigger>

        <Tabs.Trigger value="wishlist" className={styles.title}>
          WISHLIST
          {data?.length ? `(${data?.length})` : ""}
        </Tabs.Trigger>
      </Tabs.List>
      <Close asChild>
        <button className={styles["close-button"]}>X</button>
      </Close>
    </div>
  );
};

export default CartHeader;
