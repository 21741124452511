"use client";
import { Content } from "@radix-ui/react-tabs";
import clsx from "clsx";

import { Button, ButtonLink, Heading } from "@/components/atoms";
import Preamble from "@/components/atoms/Preamble/Preamble";

import useWishlistModal from "@/lib/hooks/useSelection/cart/useWishlist";
import styles from "../Cart/cart.module.css";
import CartItems from "../CartItems/CartItems";
import CartSummary from "../CartSummary/CartSummary";

// Dupilcated code from Cart since Radix was making problems.
const CartContent = ({ setTab }) => {
  const { items, modal, cart } = useWishlistModal();

  if (cart.summary?.totalQuantity === 0) {
    return (
      <Content className={clsx(styles["cart-content"])} value="cart">
        <div className={styles["cart-empty"]}>
          <Preamble>Shopping Cart</Preamble>
          <Heading as="h3" styledAs="h4">
            Your cart is empty
          </Heading>
          <div className={styles.buttons}>
            <Button onClick={() => setTab("wishlist")} outlined>
              Go to wishlist
            </Button>
            <ButtonLink
              href="/products"
              color="black"
              textColor="white"
              onClick={modal.toggleModal}
            >
              Go shopping
            </ButtonLink>
          </div>
        </div>
      </Content>
    );
  }

  return (
    <Content className={clsx(styles["cart-content"])} value="cart">
      <CartItems items={items} />
      {cart.summary?.totalQuantity > 0 && (
        <CartSummary summary={cart.summary} modal={modal} />
      )}
    </Content>
  );
};

export default CartContent;
