"use client";
import UI from "./UI";
import useVoucher from "./useVoucher";

const Vouchers = () => {
  const voucher = useVoucher();

  return <UI voucher={voucher} />;
};

export default Vouchers;
