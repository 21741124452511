import CartItem from "@/components/atoms/CartItem/CartItem";
import type { CartItemMaybeHooked } from "@/lib/hooks/useSelection/cart/useItems";

import styles from "./cart-items.module.css";
const CartItems = ({ items }: { items: CartItemMaybeHooked[] | null }) => (
  <div className={styles.container}>
    {items?.map((item, i) => {
      return <CartItem item={item} key={`${item.line}-${i}`} />;
    })}
  </div>
);

export default CartItems;
