"use client";
import useLocation from "@/lib/hooks/useLocation";
import useLocationSelector from "@/lib/hooks/useLocationSelector";
import * as Popover from "@radix-ui/react-popover";
import clsx from "clsx";
import { useState } from "react";
import Skeleton from "../Skeleton";
import styles from "./locationSelector.module.css";
// import styles from "./locationSelector.module.css";
const UI = () => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");

  const { scandinavia, rest, eu } = useLocationSelector(search);
  const location = useLocation();

  const closeModal = () => {
    setOpen(false);
    setSearch("");
  };

  const handleChange = (o: boolean) => {
    setOpen(o);
    if (!o) setSearch("");
  };

  //

  return (
    <Popover.Root open={open} onOpenChange={handleChange}>
      <Popover.Trigger asChild>
        <button
          style={{
            width: "75px",
            height: "16px",
          }}
        >
          {location?.location ? (
            `${location?.location} (${location?.currency})`
          ) : (
            <div>
              <Skeleton height="100%" />
            </div>
          )}
        </button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          className={clsx(styles.wrapper, "dialog-content-fade-in")}
          sideOffset={5}
        >
          <input
            className={styles.search}
            placeholder="Search for country..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className={styles["all-countries"]}>
            <div className={styles.countries}>
              <p className={styles["group-label"]}>Scandinavia</p>
              {scandinavia.map((item) => (
                <div key={item.country} onClick={closeModal}>
                  <button
                    onClick={item?.select}
                    key={item.country}
                    className={`${styles.country} ${
                      item?.selected && styles?.selected
                    }`}
                  >
                    {item.name}
                  </button>
                </div>
              ))}
            </div>
            <div className={styles.countries}>
              <p className={styles["group-label"]}>EU</p>
              {eu.map((item) => (
                <div key={item.country} onClick={closeModal}>
                  <button
                    onClick={item?.select}
                    key={item.country}
                    className={`${styles.country} ${
                      item?.selected && styles?.selected
                    }`}
                  >
                    {item.name}
                  </button>
                </div>
              ))}
            </div>
            <div className={styles.countries}>
              <p className={styles["group-label"]}>Rest of the world</p>
              {rest.map((item) => (
                <div key={item.country} onClick={closeModal}>
                  <button
                    onClick={item?.select}
                    key={item.country}
                    className={`${styles.country} ${
                      item?.selected && styles?.selected
                    }`}
                  >
                    {item.name}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default UI;
