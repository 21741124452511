"use client";
import * as Dialog from "@radix-ui/react-dialog";
import clsx from "clsx";
import Link from "next/link";
import type { PropsWithChildren } from "react";

import { Page } from "@/components/atoms";
import Close from "@/components/icons/Close";
import Search from "@/components/icons/Search";

import styles from "./global-search.module.css";
import useGlobalSearch from "./useGlobalSearch";

const ClientGlobalSearch = ({ children }: PropsWithChildren) => {
  const {
    open,
    toggleOpen,
    search,
    setSearch,
    navigateToPage,
    clearSearch,
    data,
  } = useGlobalSearch();

  return (
    <Dialog.Root modal={false} open={open} onOpenChange={toggleOpen}>
      <Dialog.Trigger>{children}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Content asChild>
          <form
            className={clsx(styles["search-form"], styles.active)}
            onSubmit={(e) => {
              e.preventDefault();
              navigateToPage();
            }}
          >
            <Page as="div" style={{ position: "relative", marginBlock: "0" }}>
              <input
                type="text"
                className={clsx(
                  styles.input__element,
                  search.length > 0 ? styles.active : ""
                )}
                placeholder="Search here"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              {search.length > 0 ? (
                <button onClick={navigateToPage}>
                  <Search />
                </button>
              ) : (
                <Dialog.Close asChild>
                  <button
                    type="button"
                    onClick={() => {
                      clearSearch();
                      toggleOpen();
                    }}
                  >
                    <Close />
                  </button>
                </Dialog.Close>
              )}

              {data && data.length > 0 && (
                <div className={styles.search__result}>
                  {data?.map((product, i) => (
                    <Dialog.Close asChild key={product.uri + i}>
                      <Link
                        href={product.uri}
                        className={styles.search__result__item}
                      >
                        {product.name + " - " + product?.variantName}
                      </Link>
                    </Dialog.Close>
                  ))}
                </div>
              )}
            </Page>
          </form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default ClientGlobalSearch;
