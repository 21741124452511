import styles from "./input.module.css";
import UI from "./UI";

const isDisabled = (disabled: boolean) => disabled;
const hasError = (
  message: string | undefined,
  isValid: boolean,
  required: boolean,
  validate: boolean
) => message && !isValid && required && validate;
const hasSuccess = (message: string | undefined, success: boolean) =>
  message && success;
const hasValue = (value: string) => value !== "";
const isInvalid = (isValid: boolean, required: boolean, validate: boolean) =>
  !isValid && required && validate;

const generateConditionalStyle = (
  isValid: boolean,
  message: string | undefined,
  value: string,
  disabled: boolean,
  styles: { [key: string]: string },
  validate: boolean,
  required: boolean,
  success: boolean
) => {
  const style = [];
  if (isDisabled(disabled)) style.push(styles.disabled);
  if (hasError(message, isValid, required, validate)) style.push(styles.error);
  if (hasSuccess(message, success)) style.push(styles.success);
  if (hasValue(value)) style.push(styles.smallPlaceholder);
  if (isInvalid(isValid, required, validate)) style.push(styles.invalid);
  return style.join(" ");
};

export interface InputProps {
  type?: string;
  placeholder?: string;
  value: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  disabled?: boolean;
  message?: string;
  required?: boolean;
  isValid: boolean;
  validate?: boolean;
  [key: string]: any;
}

const Input = (props: InputProps) => {
  const {
    isValid,
    message,
    value,
    disabled = false,
    validate = false,
    required = false,
    success = false,
  } = props;

  const conditionalStyle = generateConditionalStyle(
    isValid,
    message,
    value,
    disabled,
    styles,
    validate,
    required,
    success
  );

  return <UI {...props} conditionalStyle={conditionalStyle} />;
};

export default Input;
