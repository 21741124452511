import { usePathname, useRouter } from "next/navigation";
import { useState } from "react";

import type { SearchSchema } from "@/lib/centra/api/getSearchProductResults";
import { useBoolean } from "@/lib/hooks/useBoolean";
import { useDebounce } from "@/lib/hooks/useDebounce";
import { useFetch } from "@/lib/hooks/useFetch";

const useGlobalSearch = () => {
  const { value: open, toggle } = useBoolean(false);
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce<string>(search, 100);
  const { data } = useFetch<SearchSchema>(
    "/api/products/search?searchTerm=" + debouncedSearch
  );

  const toggleOpen = () => {
    toggle();
    if (!open) {
      setSearch("");
    }
  };

  const router = useRouter();
  const paths = usePathname()
    .split("/")
    .filter((x) => x.length > 0)
    .splice(1);

  const navigateToPage = () => {
    const categoryPath =
      paths?.[0] === "shop" || paths?.[0] === "products"
        ? paths.length > 0
          ? `/${paths.map((path) => path).join("/")}`
          : ""
        : "";
    const uri = "/products" + categoryPath + "?search=" + search;
    toggleOpen();
    router.push(uri);
    setSearch("");
  };

  const clearSearch = () => {
    setSearch("");
  };

  return {
    open,
    toggleOpen,
    search,
    setSearch,
    navigateToPage,
    clearSearch,
    data,
  };
};

export default useGlobalSearch;
